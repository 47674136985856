import { useEffect, useState } from 'react';
import { FilterOptions } from './types';
import {
  distanceFilterOptions,
  gradeFilterOptions,
  gradePreferencesOptions,
  subjectFilterOptions,
  subjectPreferencesOptions,
  connectSubjectFilterOptionsV2,
} from 'features/Connect/features/JobDiscovery/constants';
import marketplaceSchoolsAPI from 'features/Connect/api/marketplaceSchoolsAPI';
import { useStateParam } from 'features/Connect/features/ConnectStateCodeContextProvider';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants/connect';

interface FilterAndLoadingOptions extends FilterOptions {
  isLoadingFilterOptions: boolean;
}

export const useConnectFilterOptions = (): FilterAndLoadingOptions => {
  const jobboardState = useStateParam();
  const stateCode =
    CONNECT_JOBBOARD_STATES[jobboardState]?.stateCode || CONNECT_JOBBOARD_STATES.MISSOURI.stateCode;
  // const [districtOptions, setDistrictOptions] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [distanceOptions, setDistanceOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const path = window.location.pathname;
  const isUserPreferencesPage = path.includes('/preferences');

  useEffect(() => {
    if (!isUserPreferencesPage) {
      marketplaceSchoolsAPI.getSchoolFilterOptions({ stateCode: stateCode }).then((schools) => {
        setSchoolOptions(schools.map((school) => ({ label: school.name, value: school.nces_id })));
      });
    }

    // For now we're only using the new subject filter options for Tennessee.
    // This unblocks us from merging and we can test the new options
    if (CONNECT_JOBBOARD_STATES.TENNESSEE.stateName === jobboardState) {
      setSubjectOptions(connectSubjectFilterOptionsV2);
    } else {
      setSubjectOptions(isUserPreferencesPage ? subjectPreferencesOptions : subjectFilterOptions);
    }

    setDistanceOptions(distanceFilterOptions);
    setGradeOptions(isUserPreferencesPage ? gradePreferencesOptions : gradeFilterOptions);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobboardState]);

  return {
    // districtOptions,
    schoolOptions,
    subjectOptions,
    distanceOptions,
    gradeOptions,
    isLoadingFilterOptions: isLoading,
  };
};
