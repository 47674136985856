import React from 'react';
import { Grid, useTheme } from '@mui/material';
import { MultiSelectWithSearch } from 'sharedComponents/Select';
import { FilterContainerProps } from './types';
import { ConnectJobDiscoveryFiltersDataTestIds } from 'data-testids/ConnectDataTestIds';

export const Filters: React.FC<FilterContainerProps> = ({
  filterValues,
  filterOptions,
  isMobile,
  setSelectedGradeOptions,
  setSelectedSubjectOptions,
}) => {
  const theme = useTheme();
  return (
    <Grid container spacing={1} rowGap={0.5}>
      <Grid item xs={12} md={6} lg={'auto'}>
        <MultiSelectWithSearch
          displayName="Grade Levels"
          dataTestId={ConnectJobDiscoveryFiltersDataTestIds.GRADE_FILTER}
          handleChange={setSelectedGradeOptions}
          options={filterOptions.gradeOptions}
          values={filterValues.gradeOptions}
          tagLimit={1}
          size="small"
          sx={selectStyles(theme, isMobile)}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={'auto'}>
        <MultiSelectWithSearch
          displayName="Subjects"
          dataTestId={ConnectJobDiscoveryFiltersDataTestIds.SUBJECTS_FILTER}
          handleChange={setSelectedSubjectOptions}
          options={filterOptions.subjectOptions}
          values={filterValues.subjectOptions}
          tagLimit={1}
          size="small"
          sx={selectStyles(theme, isMobile)}
        />
      </Grid>
    </Grid>
  );
};

const selectStyles = (theme, isMobile) => ({
  backgroundColor: '#ffffff',
  width: isMobile ? '100%' : 325,
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
  },
});
