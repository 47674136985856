import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, TextField } from '@mui/material';
import { MultiSelectWithSearchMUIProps } from './types';

export const MultiSelectWithSearch: React.FC<MultiSelectWithSearchMUIProps> = ({
  values = [],
  options = [],
  displayName,
  placeholder,
  handleChange,
  allowMultiselect = true,
  tagLimit,
  size,
  sx,
  hiddenLabel,
  dataTestId,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(values);

  const onChangeHandler = (_event, value) => {
    setSelectedOptions([...value]);
  };

  useEffect(() => {
    handleChange(selectedOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  useEffect(() => {
    if (values.length > 0) {
      setSelectedOptions(values);
    }
  }, [values]);

  return (
    <FormControl fullWidth variant="outlined" size={size} sx={sx}>
      <Autocomplete
        id="multi-select-with-search"
        data-testid={dataTestId || 'multi-select-with-search'}
        placeholder={displayName}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        multiple={allowMultiselect}
        limitTags={tagLimit}
        options={options}
        size={size}
        sx={sx}
        onChange={(event, value) => {
          onChangeHandler(event, value);
        }}
        value={[...selectedOptions]}
        renderOption={(props, option) => (
          <li
            {...props}
            key={option.value}
            data-testid={`${dataTestId}-${option.label.toLowerCase()}-option`}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={displayName}
            sx={sx}
            placeholder={placeholder}
            hiddenLabel={hiddenLabel}
          />
        )}
      />
    </FormControl>
  );
};
