export enum ConnectActionButtonsDataTestIds {
  EXPRESS_INTEREST_BUTTON = 'express-interest-button',
  SAVE_SCHOOL_BUTTON = 'connect-save-school-button',
}

export enum ConnectAlertsBannerDataTestIds {
  NOT_SUBSCRIBED_BANNER = 'connect-alerts-banner-not-subscribed',
  SUBSCRIBED_BANNER = 'connect-alerts-banner-subscribed',
}

export enum ConnectCandidatePreferencesDataTestIds {
  COMPLETE_PROFILE_TEXT = 'connect-candidate-profile-complete-profile-title-text',
}

export enum ConnectJobDiscoveryFiltersDataTestIds {
  DISTANCE_FILTER = 'connect-distance-filter-dropdown',
  GRADE_FILTER = 'connect-grade-level-filter',
  LOCATION_INPUT = 'connect-location-filter-input',
  SCHOOL_SEARCH_FILTER = 'connect-job-discovery-school-search-filter',
  SUBJECTS_FILTER = 'connect-subject-filter',
  UPDATE_DISTANCE_FROM_LOCATION_SUBMIT_BUTTON = 'connect-location-filter-submit-button',
}

export enum ConnectJobDiscoveryGatedResultsCardDataTestIds {
  GATED_RESULTS_CARD = 'connect-job-discovery-gated-results-card-container',
  HEADER_TEXT = 'connect-job-discovery-gated-results-card-header-text',
  SIGNUP_BUTTON = 'gated-results-card-signup-button',
}

export enum ConnectJobDiscoverySchoolCardDataTestIds {
  SCHOOL_CARD = 'connect-job-discovery-school-card-container',
}

export enum ConnectLazyRegistrationLoginSignUpPageDataTestIds {
  EMAIL_INPUT = 'connect-lazy-registration-login-sign-up-page-email-input',
  SUBMIT_BUTTON = 'connect-lazy-registration-login-sign-up-page-submit-button',
}

export enum ConnectMarketingInputAndButtonDataTestIds {
  COMPLETE_PROFILE_BUTTON = 'connect-marketing-banner-complete-profile-button',
  EMAIL_INPUT = 'marketing-email-input-jobs-discovery',
  SIGN_UP_BUTTON = 'marketing-sign-up-button',
}

export enum ConnectMoreInfoSliderDataTestIds {
  MORE_INFO_SLIDER = 'connect-job-discovery-more-info-slider',
}
