import * as Sentry from '@sentry/browser';
import axios from 'axios';

import { endRecurringAuthCheck, startRecurringAuthCheck } from '../utils/authutils';
import { PROD_URL, STAGING_URL } from '../utils/constants';
import { user_type } from '../utils/enums';
import { googleNameError } from '../utils/message';
import { featureTags } from 'context/featureContext';

export const USER_TYPE = user_type().reduce((obj, item) => {
  obj[item.value] = item.key;
  return obj;
}, {});

export const SUPER_ADMIN_USER_TYPES = [USER_TYPE.super_admin];
export const DISTRICT_ADMIN_USER_TYPES = [USER_TYPE.district_admin, USER_TYPE.super_admin];
export const DISTRICT_USER_TYPES = [...DISTRICT_ADMIN_USER_TYPES, USER_TYPE.district_user];
export const SCHOOL_ADMIN_USER_TYPES = [USER_TYPE.school_admin]; // Missing school user?
export const CANDIDATE_USER_TYPES = [USER_TYPE.candidate];

// list of user_types for checklist validation
export const CHECKLIST_USER_TYPES = [...DISTRICT_USER_TYPES, ...SCHOOL_ADMIN_USER_TYPES];

var authenticatedUser = null;

export default {
  checkAuthenticated() {
    return axios.get('/api/auth/').then((r) => {
      this.setUser(r.data);
      return r.data;
    });
  },

  login(email, password) {
    return axios
      .post('/api/auth/', { username: email, password: password })
      .then((r) => {
        this.setUser(r.data);
        // segment identify
        if (window.analytics) {
          window.analytics.identify(authenticatedUser.id, {
            username: authenticatedUser.email,
            created_at: new Date(),
          });
        }
        startRecurringAuthCheck();
        return r.data;
      })
      .catch((error) => console.log(error));
  },

  logout() {
    return axios.delete('/api/auth/').then(() => {
      this.setUser(null);
      window.localStorage.clear();
      endRecurringAuthCheck();
    });
  },

  releaseHijack() {
    window.localStorage.clear();
    return axios.get('/admin/hijack/release-hijack/');
  },

  handleReleaseHijack() {
    this.releaseHijack().then(() => window.location.assign('/admin/core/user/'));
  },

  register(
    fname,
    lname,
    district,
    email,
    password,
    type,
    agree_tos = false,
    is_nimble_connect_signup = false
  ) {
    return axios
      .post('/api/auth/register/', {
        fname: fname,
        lname: lname,
        district: district,
        username: email,
        email: email,
        password: password,
        type: type,
        agree_tos: agree_tos,
        did_signup_with_nimble_connect: is_nimble_connect_signup,
      })
      .then((r) => {
        this.setUser(r.data);
        return r.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  loggedIn() {
    return !!authenticatedUser;
  },

  setUser(user) {
    if (user && user.id) {
      authenticatedUser = user;

      // Get the user type label for intercom, and pendo
      const user_type_label = user_type().filter((userType) => {
        return userType.key === user.profile.user_type;
      })[0].label;

      Sentry.setUser({
        id: user.id,
        username: user.username,
        email: user.email,
        first_name: user?.first_name || '',
        last_name: user?.last_name || '',
      });
      // pendo configurations
      if (window.location.hostname === STAGING_URL || window.location.hostname === PROD_URL) {
        window.pendo.initialize({
          visitor: {
            id: user.id, // Required if user is logged in
            email: user.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: user?.name || '', // Recommended if using Pendo Feedback
            role: user_type_label, // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
            user_type: user_type_label,
            creation_date: user?.date_joined || '',
            last_login: user?.last_login || '',
            city: user.profile?.address_city || '',
            state: user.profile?.address_city || '',
            is_staging: window.location.hostname === STAGING_URL,
            is_prod: window.location.hostname === PROD_URL,
          },

          account: {
            id: user.profile.district ? user.profile.district.id : '', // Required if using Pendo Feedback
            name: user.profile.district ? user.profile.district.name : '', // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
            parent_organization: user.profile.district?.parent_organization || '',
            posting_only: user.profile.district?.posting_only,
          },
        });
      }
    } else {
      const pathName = window.location.pathname || '';
      const connectMatch = pathName.includes('/connect');

      if (
        connectMatch &&
        (window.location.hostname === STAGING_URL || window.location.hostname === PROD_URL)
      ) {
        window.pendo.initialize({
          visitor: {},
        });
      }
      authenticatedUser = null;
    }
  },

  getUser() {
    return authenticatedUser;
  },

  async getUserAsync() {
    return this.checkAuthenticated().then((r) => {
      return Object.entries(r).length === 0 && r.constructor === Object ? null : r;
    });
  },
  isStateLevelAdminUser(user) {
    user = user || authenticatedUser;
    return user && user.profile.is_state_level_admin;
  },
  isSuperAdminUser(user) {
    user = user || authenticatedUser;
    return user && user.profile.user_type === USER_TYPE.super_admin;
  },
  isDistrictAdmin(user) {
    user = user || authenticatedUser;
    return user && DISTRICT_ADMIN_USER_TYPES.includes(user.profile.user_type);
  },
  // TODO: This is used everywhere when it should really be the one above
  isDistrictUser(user) {
    user = user || authenticatedUser;
    return user && DISTRICT_USER_TYPES.includes(user.profile.user_type);
  },

  isDistrictUserOnly(user) {
    user = user || authenticatedUser;
    return user && user.profile.user_type === USER_TYPE.district_user;
  },

  isSchoolAdmin(user) {
    // currently the same as isSchoolUser, but we expect to add a SchoolUser
    // user_type in the future.
    user = user || authenticatedUser;
    return user && SCHOOL_ADMIN_USER_TYPES.includes(user.profile.user_type);
  },

  isSchoolUser(user) {
    user = user || authenticatedUser;
    return user && SCHOOL_ADMIN_USER_TYPES.includes(user.profile.user_type);
  },

  isCandidateUser(user) {
    user = user || authenticatedUser;
    return user && CANDIDATE_USER_TYPES.includes(user.profile.user_type);
  },

  isIndianaDistrict(user) {
    user = user || authenticatedUser;
    return user && user.profile.district.indiana_district;
  },

  isValidChecklistUser(user) {
    // users in posting-only districts don't see checklist
    if (this.inPostingOnlyDistrict()) {
      return false;
    }
    user = user || authenticatedUser;
    return user && CHECKLIST_USER_TYPES.includes(user.profile.user_type);
  },

  isAdminUser(user) {
    user = user || authenticatedUser;
    return this.isSchoolAdmin(user) || this.isDistrictUser() || this.isSuperAdminUser();
  },

  hasApplicationConfirmationTemplates(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes('application_confirmation_template');
  },

  hasJobActivityFeed(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes('job_activity_feed');
  },

  hasOnboardingModule(user) {
    user = user || authenticatedUser;
    return user?.profile?.district?.has_onboarding_module;
  },

  hasProspectsModule(user) {
    user = user || authenticatedUser;
    return user?.profile?.district?.has_prospects_module;
  },

  hasTalentMarketplace(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes('has_talent_marketplace');
  },

  hasTemplates(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes('job_templates');
  },

  hasJobClosureNotifications(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes('job_closure_notifications');
  },

  hasApplicationAttachmentDelete(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes('delete_application_attachments');
  },

  hasInternalCandidateNoteDelete(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes('internal_candidate_note_delete');
  },

  hasDeleteIncompleteCandidateTasks(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes('delete_incomplete_candidate_tasks');
  },

  hasConnectJobAlerts(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes(featureTags.CONNECT_JOB_ALERTS);
  },

  hasConnectSearchResults(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes(featureTags.CONNECT_SEARCH_RESULTS);
  },

  hasDisableCandidateLiveSearch(user) {
    user = user || authenticatedUser;
    return user?.enabled_features.includes(featureTags.DISABLE_CANDIDATE_LIVE_SEARCH);
  },

  logInWithGoogle(id_token) {
    return axios
      .post('/api/auth/google_login/', {
        google_token: id_token,
      })
      .then((r) => {
        this.setUser(r.data);
        return r.data;
      })
      .catch((error) => {
        if (error.response.status === 500) {
          googleNameError();
        }
      });
  },

  inPostingOnlyDistrict() {
    // so many question marks
    return authenticatedUser?.profile?.district?.posting_only ?? false;
  },
};
