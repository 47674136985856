import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ConnectGatedActions } from 'features/Connect/utils/connectEnums';
import { ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { Actions, School } from 'types/connectTypes';
import { User } from 'types/types';
import { Screen, Screens, GatedActionResponse } from './types';
import { History } from 'history';
import ExpressInterestAPI from 'features/Connect/api/expressInterestAPI';
import { connectRedirectUrls, connectUrlParams } from '../JobDiscovery/constants';
import { CONNECT_JOBBOARD_STATES } from 'utils/constants';
import { buildUrl } from 'utils/util';

export const MISSOURI_STATE_ID = 25;
const connectRootUrl = '/connect';
const connectProfileRootUrl = '/connect/profile';
const connectPreferencesRootUrl = '/connect/preferences';
const connectQualificationsRootUrl = '/connect/qualifications';

export function checkFileMeetsRequirements(event: ChangeEvent<HTMLInputElement>): {
  fileData: File;
  errors: string[];
} {
  const fileData = event.target.files[0];
  let errors: string[];

  /* ensure no empty files are uploaded */
  if (typeof fileData === 'undefined' || fileData.size === 0) {
    errors.push('Upload is empty, please try again');
  }

  if (fileData.size > 7 * 1048576) {
    errors.push('File must be smaller than 7 MB');
  }
  /* only proper file types can be uploaded */
  if (
    !['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'].includes(
      fileData.name.split('.').pop().toLowerCase()
    )
  ) {
    errors.push(
      'Please ensure your file ends in one of the following: .pdf, .doc, .docx, .jpg, .png'
    );
  }
  /**
   * ~ 30 characters are prepended to file name (/resumes/None/(timestamp)), and max_length
   * is 255, so ensure input file name is no more than 215 characters, to be safe.
   */
  if (fileData.name.length > 215) {
    errors.push('Please ensure your file name is fewer than 215 characters');
  }

  return { fileData, errors };
}

export const formatResumeFileName = (resumeFilePath: string): string => {
  if (!resumeFilePath) {
    return '';
  } else {
    const path = resumeFilePath.split('?')[0];
    const pathArr = path.split('/');
    return pathArr[pathArr.length - 1].substring(14);
  }
};

export function moreInfoOnClickHandler(school: School, actions: Actions, vacancyId: string): void {
  if (vacancyId !== '0') {
    actions.setSelectedVacancyId(vacancyId);
  }
  actions.setSelectedSchool(school);
  actions.setMoreInfoSliderIsOpen(true);
}

export function createButtonUrl({
  basePath,
  schoolId,
  action,
  jobboardState,
}: {
  basePath: string;
  schoolId?: string;
  action?: string;
  jobboardState?: string;
}): string {
  let url = basePath;
  const queryParams = [];

  if (jobboardState) {
    queryParams.push(`${connectUrlParams.REDIRECT}=${jobboardState}`);
  }

  if (action === ConnectGatedActions.ALERT) {
    queryParams.push(`action=${action}`);
  } else if (schoolId && action) {
    queryParams.push(`school=${schoolId}&action=${action}`);
  }

  if (queryParams.length) {
    url += `?${queryParams.join('&')}`;
  }

  return url;
}

export const getProfileFlowPage = (pathname: string, flowScreens: Screens): Screen => {
  const segments = pathname.split('/');
  const pageSegment = segments[segments.length - 1];
  return flowScreens[pageSegment];
};

const getSearchParams = (searchParams) => {
  return {
    gatedAction: searchParams.get('action')?.toLowerCase(),
    schoolId: searchParams.get('school'),
    jobboardState: searchParams.get('redirect'),
  };
};

export const getGatedActionDisplayInfo = (): GatedActionResponse => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { gatedAction, schoolId, jobboardState } = getSearchParams(searchParams);

  let flowDisplayInformation;
  switch (gatedAction) {
    case ConnectGatedActions.ALERT:
      flowDisplayInformation = {
        stepper: {
          steps: ['Contact Info', 'Add Alert', 'Get Discovered'],
          mobileSteps: ['Contact Info', 'Add Alert', 'Get Discovered'],
        },
        alreadyLoggedInRedirect: createButtonUrl({
          basePath: `${connectPreferencesRootUrl}${jobboardState}`,
        }),
        screens: {
          login: {
            step: null,
            headerText: 'Create job alert',
            continueToUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['email'],
          },
          signup: {
            step: null,
            headerText: 'Create job alert',
            continueToUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['email'],
          },
          profile: {
            step: 0,
            headerText: 'Add your contact info',
            continueToUrl: createButtonUrl({
              basePath: connectPreferencesRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['firstName', 'lastName', 'openToContact'],
          },
          preferences: {
            step: 1,
            headerText: 'Set up your alert',
            continueToUrl: createButtonUrl({
              basePath: connectQualificationsRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['subject', 'grade', 'distance'],
          },
          qualifications: {
            step: 2,
            headerText: 'Get discovered by principals',
            continueToUrl: createButtonUrl({
              basePath: connectRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            backUrl: createButtonUrl({
              basePath: connectPreferencesRootUrl,
              action: gatedAction,
              jobboardState: jobboardState,
            }),
            primaryButtonLabel: 'Save & Finish',
            primaryButtonLabelMobile: 'Finish',
            requiredFields: [],
          },
        },
      };
      break;
    default:
      flowDisplayInformation = {
        stepper: {
          steps: ['Profile', 'Qualifications', 'Preferences'],
          mobileSteps: ['Profile', 'Qualifications', 'Preferences'],
        },
        alreadyLoggedInRedirect: createButtonUrl({
          basePath: connectRootUrl,
          action: gatedAction,
          schoolId: schoolId,
          jobboardState: jobboardState,
        }),
        screens: {
          login: {
            step: null,
            headerText: 'Log in to your account',
            continueToUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['email'],
          },
          signup: {
            step: null,
            headerText: 'Create Profile',
            subHeadingText:
              'Create your Nimble account to start connecting with principals in your area.',
            continueToUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['email'],
          },
          profile: {
            step: 0,
            headerText: null,
            continueToUrl: createButtonUrl({
              basePath: connectQualificationsRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            backUrl: null,
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['firstName', 'lastName', 'openToContact'],
          },
          qualifications: {
            step: 1,
            headerText: 'Tell us your qualifications',
            continueToUrl: createButtonUrl({
              basePath: connectPreferencesRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            backUrl: createButtonUrl({
              basePath: connectProfileRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            primaryButtonLabel: 'Continue',
            primaryButtonLabelMobile: 'Continue',
            requiredFields: ['resume', 'certifications'],
          },
          preferences: {
            step: 2,
            headerText: 'Tell us your preferences',
            continueToUrl: createButtonUrl({
              basePath: `${connectRootUrl}/${jobboardState}`,
              action: gatedAction,
              schoolId: schoolId,
            }),
            backUrl: createButtonUrl({
              basePath: connectQualificationsRootUrl,
              action: gatedAction,
              schoolId: schoolId,
              jobboardState: jobboardState,
            }),
            primaryButtonLabel: 'Save & Finish',
            primaryButtonLabelMobile: 'Finish',
            requiredFields: [],
          },
        },
      };
      break;
  }

  return {
    flowDisplayInformation: flowDisplayInformation,
    pageDisplayInformation: getProfileFlowPage(pathname, flowDisplayInformation.screens),
  };
};

export const updateConnectFirstProfileSetupDateTime = async (user) => {
  if (!user?.profile?.connect_first_profile_setup && !user?.is_hijacked) {
    const currentTime = new Date(moment.now()).toISOString();
    await ExpressInterestAPI.updateConnectFirstProfileSetupDateTime(user.id, currentTime);
  }
};

export const useConnectActions = () => {
  const history = useHistory();

  const checkUserHasConnectFirstProfileSetupDateTime = (
    user: User,
    jobboardState: string,
    action: ConnectGatedActions,
    school: School = null
  ) => {
    const urlParamsToAdd = {
      [connectUrlParams.REDIRECT]: CONNECT_JOBBOARD_STATES[jobboardState].urlParam,
      [connectUrlParams.ACTION]: action,
    };

    if (school) {
      urlParamsToAdd[connectUrlParams.SCHOOL] = school.nces_id;
    }

    if (!user) {
      history.push(buildUrl(connectRedirectUrls.SIGN_UP, urlParamsToAdd));
      return false;
    } else if (!user?.profile?.connect_first_profile_setup) {
      history.push(buildUrl(connectRedirectUrls.PROFILE, urlParamsToAdd));
      return false;
    } else {
      return true;
    }
  };

  const handleConnectUserAction = async (
    user: User,
    jobboardState: string = CONNECT_JOBBOARD_STATES.MISSOURI.urlParam,
    action: ConnectGatedActions,
    callbackFunction: () => Promise<void>,
    school: School | null = null
  ) => {
    const hasActiveDateTime = checkUserHasConnectFirstProfileSetupDateTime(
      user,
      jobboardState,
      action,
      school
    );
    if (hasActiveDateTime) {
      await callbackFunction();
    }
  };

  return {
    handleConnectUserAction,
  };
};

export const checkUserProfileCompletion = (
  user: User,
  history: History,
  jobboardState?: string,
  school?: School,
  action?: ConnectGatedActions
): boolean => {
  const formCompletionStatuses = user.preference?.nimble_connect_profile?.form_completion_statuses;
  const urlParamsToAdd = {
    [connectUrlParams.REDIRECT]: CONNECT_JOBBOARD_STATES[jobboardState].urlParam,
  };

  if (school && action) {
    urlParamsToAdd[connectUrlParams.SCHOOL] = school.nces_id;
    urlParamsToAdd[connectUrlParams.ACTION] = action;
  }

  if (!formCompletionStatuses.profile_form) {
    history.push(buildUrl(connectRedirectUrls.PROFILE, urlParamsToAdd));
    return false;
  } else if (
    !formCompletionStatuses.resume_and_credentials_form &&
    (action === ConnectGatedActions.EXPRESS_INTEREST || !action)
  ) {
    history.push(buildUrl(connectRedirectUrls.QUALIFICATIONS, urlParamsToAdd));
    return false;
  } else if (action === ConnectGatedActions.EXPRESS_INTEREST) {
    history.push(buildUrl(connectRedirectUrls.PROFILE, urlParamsToAdd));
    return false;
  } else {
    history.push(buildUrl(connectRedirectUrls.RETURN_TO, urlParamsToAdd));
    return true;
  }
};
